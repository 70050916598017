// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Restwertentschädigung Stadt Zürich"
        meta={[
          { name: 'description', content: 'Entschädigung bei vorzeitigem Heizungsersatz:  ➤ Erklärung ✔️ Ökologischer Nutzen ✔️ Bedingungen ✔️Jetzt informieren!' },
          { name: 'keywords', content: 'restwertentschädigung' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='6NrxtsW0Es5dpFf6pOVeRi-1xM9aDn2saHWC9dtWxn9AF'
          id='stadt-zuerich-restwertentschaedigung-bei-vorzeitigem-heizungsersatz'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "1xM9aDn2saHWC9dtWxn9AF",
    "updatedAt": "2024-03-04T14:51:50.053Z",
    "title": "Stadt Zürich: Restwertentschädigung bei vorzeitigem Heizungsersatz",
    "anchorName": "stadt-zuerich-restwertentschaedigung-bei-vorzeitigem-heizungsersatz",
    "text": "<ul>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/stadt-zuerich-restwertentschaedigung-bei-vorzeitigem-heizungsersatz/#was-ist-die-restwertentschaedigung-bei-vorzeitigem-heizungsersatz\">Was ist die Restwertentschädigung bei vorzeitigem Heizungsersatz?</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/stadt-zuerich-restwertentschaedigung-bei-vorzeitigem-heizungsersatz/#lohnt-es-sich-aus-oekologischer-sicht-eine-heizung-vorzeitig-zu-ersetzen\">Lohnt es sich aus ökologischer Sicht, eine Heizung vorzeitig zu ersetzen?</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/stadt-zuerich-restwertentschaedigung-bei-vorzeitigem-heizungsersatz/#welche-bedingungen-gibt-es-und-was-muss-man-beim-antrag-beachten\">Welche Bedingungen gibt es und was muss man beim Antrag beachten?</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/stadt-zuerich-restwertentschaedigung-bei-vorzeitigem-heizungsersatz/#wie-lange-gibt-es-die-restwertentschaedigung-noch\">Wie lange gibt es die Restwertentschädigung noch?</a></li>\n</ul>\n<p>Eigenheimbesitzer mit alter Heizung aufgepasst: Um das Klimaziel «Netto-Null 2040» zu erreichen, zahlt die Stadt Zürich ein Fördergeld, wenn man seine Öl- und Gasheizung vorzeitig durch eine Wärmepumpe oder einen Anschluss an die Fernwärme ersetzt. Da auch die Installation von Öl- und Gasheizungen nicht günstig ist, soll die sogenannte Restwertentschädigung diese Investitionen teilweise ausgleichen, auch wenn sie noch nicht amortisiert sind. Das soll Eigenheimbesitzer motivieren, ihre umweltschädliche Heizung bereits vorzeitig auszutauschen – z. B. gegen eine umweltfreundliche <a href=\"https://www.heizungsmacher.ch/waermepumpen/\">Wärmepumpe</a>.</p>\n",
    "image": {
        "description": "",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/60H7tRkXIvJvApYRAJIcxh/1340ee8dcad8a256da80603525b86927/2308---Restwertentsch__digung-Referenzbild.png"
    }
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='6NrxtsW0Es5dpFf6pOVeRi-48bQCsvkLZbpoa3tLgBD4Q'
          id='was-ist-die-restwertentschaedigung-bei-vorzeitigem-heizungsersatz'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "48bQCsvkLZbpoa3tLgBD4Q",
    "updatedAt": "2023-08-16T18:06:40.057Z",
    "title": "Was ist die Restwertentschädigung bei vorzeitigem Heizungsersatz?",
    "anchorName": "was-ist-die-restwertentschaedigung-bei-vorzeitigem-heizungsersatz",
    "text": "<p><strong>Die Restwertentschädigung ist eine zusätzliche Förderung, kann also ergänzend zu den üblichen Fördermöglichkeiten ausgezahlt werden</strong>. Sie setzt sich aus einem pauschalen und einem variablen Teil zusammen: Es gibt pauschal CHF 4000 für Heizungen, die maximal 25 Jahre alt sind. Ausserdem gibt es einen variablen Betrag für Heizungen, die maximal 20 Jahre alt sind: Dieser Betrag ist vom Alter und der Grösse der Anlage abhängig und fällt höher aus, je jünger die Heizung ist.</p>\n<p>Mehr über die <a href=\"https://www.heizungsmacher.ch/foerdergelder/kanton-zuerich/\">Förderungen im Kanton Zürich </a></p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='6NrxtsW0Es5dpFf6pOVeRi-1jC98p8BeSTEDmgtooOSrN'
          id='lohnt-es-sich-aus-oekologischer-sicht-eine-heizung-vorzeitig-zu-ersetzen'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "1jC98p8BeSTEDmgtooOSrN",
    "updatedAt": "2023-08-16T18:07:59.497Z",
    "title": "Lohnt es sich aus ökologischer Sicht, eine Heizung vorzeitig zu ersetzen?",
    "anchorName": "lohnt-es-sich-aus-oekologischer-sicht-eine-heizung-vorzeitig-zu-ersetzen",
    "text": "<p>Ja, aus ökologischer Sicht lohnt es sich immer, eine fossilbetriebene Heizung zu ersetzen. Dafür sprechen vor allem die hohen Treibhausgasemissionen, die entstehen, während die Heizung in Betrieb ist. Die Emissionen, die bei der Herstellung einer Gas- oder Ölheizung entstehen, fallen im Vergleich dazu nicht ins Gewicht. <strong>Natürlich entstehen auch bei der Herstellung einer Wärmepumpe Treibhausgasemissionen – diese sind allerdings bereits nach weniger als zwei Jahren ökologisch ausgeglichen</strong>.</p>\n<p>Sind Sie noch unsicher, was bei einem Heizungsersatz auf Sie zukommt? In unseren Wissensartikeln erfahren Sie alles Wichtige rund um <a href=\"https://www.heizungsmacher.ch/wissen/gasheizung-ersetzen-kosten-foerderungen-und-regelungen-in-der-schweiz/\">Gasheizung ersetzen</a> bzw. <a href=\"https://www.heizungsmacher.ch/wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen/\">Ölheizung ersetzen</a>.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='6NrxtsW0Es5dpFf6pOVeRi-3UbgtNJ363Ei0RQTxnpNhQ'
          id='welche-bedingungen-gibt-es-und-was-muss-man-beim-antrag-beachten'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "3UbgtNJ363Ei0RQTxnpNhQ",
    "updatedAt": "2023-08-17T11:31:26.400Z",
    "title": "Welche Bedingungen gibt es und was muss man beim Antrag beachten?",
    "anchorName": "welche-bedingungen-gibt-es-und-was-muss-man-beim-antrag-beachten",
    "text": "<ul>\n<li>Die Gas- oder Ölheizung ist <strong>höchstens 25 Jahre alt</strong>.</li>\n<li>Die Gas- oder Ölheizung wird <strong>durch einen Anschluss an die Fernwärme oder eine Wärmepumpe ersetzt</strong>.</li>\n<li>Es wird ein <strong>gemeinsames Gesuch</strong> für die Restwertentschädigung und die sonstigen Förderungen gestellt.</li>\n<li><strong>Das Gesuch muss vor Baubeginn bewilligt</strong> oder vorzeitig freigegeben werden.</li>\n<li><strong>Für Gasheizungen in einem Gebiet, in dem die Stilllegung der Gasnetze angekündigt wurde</strong>: Die Gasheizung wurde vor Ankündigung der Stilllegung in Betrieb genommen.</li>\n<li><strong>Die Entschädigung wird</strong> für Liegenschaften von Privatpersonen, Körperschaften und Stiftungen des privaten Rechts sowie <strong>von städtischen Eigenwirtschaftsbertieben und Wohnbaustiftungen beantragt</strong>.</li>\n</ul>\n<p>Ihr Gesuch reichen Sie über das <a href=\"https://portal.energie-foerderung.ch/zs\">Webportal Energieförderprogramm Stadt Zürich</a> ein. <strong>Folgende Unterlagen sind dafür notwendig</strong>:</p>\n<ul>\n<li><strong>Fotos der bestehenden Heizungsanlage</strong> inklusive Typenschild mit Baujahr des zu ersetzenden Heizkessels</li>\n<li><strong>Gebäudepläne mit Nachweis</strong> der Energiebezugsfläche (EBF)</li>\n<li><strong>Prinzipschema</strong> der neuen Heizungsanlage</li>\n</ul>\n<p>Abhängig von der Art Ihrer neuen Heizung müssen Sie eventuell noch weitere Unterlagen einreichen.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='6NrxtsW0Es5dpFf6pOVeRi-7Ibet1SxEDYxC8t31PaeSb'
          id='wie-lange-gibt-es-die-restwertentschaedigung-noch'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "7Ibet1SxEDYxC8t31PaeSb",
    "updatedAt": "2023-08-16T18:11:12.604Z",
    "title": "Wie lange gibt es die Restwertentschädigung noch?",
    "anchorName": "wie-lange-gibt-es-die-restwertentschaedigung-noch",
    "text": "<p><strong>Die Restwertentschädigung für den vorzeitigen Heizungsersatz wird im Rahmen des Förderprogramms Heizungsersatz und Heizungsoptimierung gezahlt</strong>. Das Förderprogramm läuft seit dem 1. Oktober 2022 und soll noch bis zum 31. Dezember 2024 laufen – oder bis das Objektbudget von 13,5 Millionen Franken aufgebraucht ist.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='6NrxtsW0Es5dpFf6pOVeRi-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
